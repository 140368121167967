import { GappApi,GappBiz } from "@geeesy/type-interfaces";
// const API_Name = "https://4z7e0vcyok.execute-api.ap-southeast-1.amazonaws.com/dev";
// const API_Path = '/onlineorder'
import axios from "axios";

const API_Name = `${process.env.VUE_APP_AMPLIFY_API_COMMERCE}/public`;

const API_Path = "/order-link";
const API_Path_salepage = "/order-salepage";

// Gen PromptPay QR Code
const API_Name_PromptPay= 'https://68042vzdt1.execute-api.ap-southeast-1.amazonaws.com/beta'
const API_Path_PromptPay = "/payment/generate/promptpay"

export default class OrderService {
  //   getDetailOnlineOrder(compId:any, orderId:any) {
  //     return axios.get(`${API_Name}${API_Path}/detail/${compId}/${orderId}`)
  //   }

  //   updateDetailOnlineOrder(body:any){
  //     return axios.put(`${API_Name}${API_Path}/updatestatus/${body.compId}/${body.orderId}`,body)
  //   }

  getByShortOrderId(shortOrderId: string) {
    console.log("API_Name => ",API_Name);
    console.log("DOMAIN => ",process.env.VUE_APP_AMPLIFY_API_COMMERCE);
    const path = `${API_Name}/short/${shortOrderId}`
        
    return axios.get(path);
  }

  getDetailOnlineOrder(
    compId: string,
    orderId: string,
    type: string,
    salepageId: string
  ) {
    console.log("API_Name => ",API_Name);
    console.log("DOMAIN => ",process.env.VUE_APP_AMPLIFY_API_COMMERCE);
    const path =
      type === "salepage"
        ? `${API_Name}${API_Path_salepage}/${compId}/${orderId}/${salepageId}`
        : `${API_Name}${API_Path}/${compId}/${orderId}/${type.toUpperCase()}`;
    return axios.get(path);
  }

  get getEnvName(){
    const split = API_Name.split("/")
    return split[split.length - 2] === 'beta' ? 'dev' : split[split.length - 2]
  }

  updateDetailOnlineOrder(
    compId: string,
    orderId: string,
    body: GappApi.ConfirmOrderLink
  ) {
    return axios.post(
      `${API_Name}${API_Path}/confirm/${compId}/${orderId}`,
      body
    );
  }

  cancelOrder(orderType: string, compId: string, orderId: string, salepageId?: string){
    if (orderType !== 'ORDER_LINK') {
      return axios.put( `${process.env.VUE_APP_AMPLIFY_API_COMMERCE}/purchased/expiry/cancel?orderType=${orderType}&compId=${compId}&orderId=${orderId}&salepageId=${salepageId}`)
    } else {
      return axios.put( `${process.env.VUE_APP_AMPLIFY_API_COMMERCE}/purchased/expiry/cancel?orderType=${orderType}&compId=${compId}&orderId=${orderId}`)
    }
  }

  updatePaymentOnlineOrder(
    compId: string,
    orderId: string,
    body: GappBiz.CreatePaymentRowInOrder,
    type: string,
    salepageId:string
  ) {
    const http =
        type === "salepage"
            ? `${API_Name}${API_Path_salepage}/confirm/${compId}/${orderId}/${salepageId}`
            : `${API_Name}${API_Path}/confirm/payment/${compId}/${orderId}`;

    // const http = `${API_Name}/payment`

    return axios.post(http, body);
  }

  getPaymentRows(
    compId: string,
    orderId: string,
  ) {

    const http =  `${API_Name}/payment/list/${compId}/${orderId}`;

    // const http = `${API_Name}/payment`
    return axios.get(http);
  }

  getDateNowToISOString(){
    const date = new Date()
    date.setHours(date.getHours() + 7)
    return date.toISOString().slice(0, 10)
  }

  convertDateToISOString(dateIn):string{
    const date = new Date(dateIn)
    date.setHours(date.getHours() + 7)
    return date.toISOString().slice(0, 10)
  }

  convertTimeToISOString(dateIn):string{
    const date = new Date(dateIn)
    date.setHours(date.getHours() + 7)
    return date.toISOString().slice(11, 16)
  }

  genQrCodePromptPay(
    body: GappBiz.GenQrPromptPay
  ) {
    return axios.post(
      `${API_Name_PromptPay}${API_Path_PromptPay}`,
      body
    );
  }
}
