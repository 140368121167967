
import compHeader from "../components/header.component.vue";
import compProductInfo from "../components/product-info.component.vue";
import compShipping from "../components/shipping.component.vue";
import compBill from "../components/bill.component.vue";
import compDetailShipping from "../components/detail-shipping.component.vue";
import compOrderStatus from "../components/order-status.component.vue";
import compPriceSummary from "../components/price-summary.component.vue";
import expiryComp from "../components/expiry.component.vue";

import {
  StatusOrder,
  StatusOrderSeller,
} from "@geeesy/type-interfaces/lib/type-order";
import OrderService from "../core/services/order.service";
import { defineComponent } from "vue";
const order = new OrderService();
export default defineComponent({
  name: "pageDetail",
  data() {
    return {
      detail: null,
      StatusOrder: StatusOrder,
      StatusOrderSeller: StatusOrderSeller
    };
  },
  mounted() {
    const route = this.$route as any;
    order.getByShortOrderId(route.params.shortOrderId).then((short) => {
      console.log({short});
      const shortData = short.data.data
      console.log({shortData});
      order
      .getDetailOnlineOrder(
        shortData.compId,
        shortData.orderId,
        route.params.type,
        shortData.salepageId
      )
      .then(
        (response) => {
          this.detail = {
            ...response.data.data,
            accountingData: {
              ...response.data.data.accountingData,
              shippingCost:
                  response.data.data.accountingData.shippingCost == 0 &&
                  response.data.data.statusOrderOnSeller == "awaiting_confirm"
                      ? null
                      : response.data.data.accountingData.shippingCost,
            },
          }

        }
      );
    })
   

  },
  methods: {
    triggerForm(): void {
      console.log("click send shipping");
      const ref = this.$refs as any;
      ref.compShipping.submitForm();
    },
    formatDate(date){
      const newDate = new Date(date)
      const day = newDate.getDate().toString().length === 1 ? `0${newDate.getDate()}` : newDate.getDate()
      const month = newDate.getMonth().toString().length === 1 ? `0${newDate.getMonth()+1}` : newDate.getMonth()+1
      const year = newDate.getFullYear()
      const hours = newDate.getHours()
      const minutes = newDate.getMinutes()

      return `${day}/${month}/${year} ${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)} น.`
    },
  },
  components: {
    compHeader,
    compProductInfo,
    compShipping,
    compBill,
    compDetailShipping,
    compOrderStatus,
    compPriceSummary,
    expiryComp
  },
});
