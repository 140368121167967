<template>
  <div class="w-52 items-end rounded-md border inputBorder">
    <div class="flex items-center flex-1 h-full">
      <input
        class="flex-1 w-20 bg-transparent px-3 focus:outline-none h-full text-right border-r border-gray-300"
        ref="inputRef"
        type="text"
        :value="formattedValue"
      />
      <!-- <span class="text-center px-3">บาท</span> -->
      <span class="text-center px-3">บาท</span>
    </div>
  </div>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: Number, // Vue 2: value
    options: Object,
  },
  setup(props) {
    const { formattedValue, inputRef } = useCurrencyInput(props.options);

    return { inputRef, formattedValue };
  },
};
</script>