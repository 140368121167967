<template>
  <Layout>
    <div class="mb-4">
    <div
      class="flex gap-4 card p-5"
    >

      <div class="flex-1">
        <div class="flex justify-center text-center gap-4">
          <div class="flex items-center">
            <img class="w-10" :src="require('@/assets/images/icon-clock.svg')" alt="" />
          </div>
          <template v-if="daysToDday">
          <div>
            <div class="text-3xl font-bold" style="color: #ff8e00 !important;">
              {{ pad(daysToDday) }}
            </div>
            <small>วัน</small>
          </div>
          <div class="text-3xl" style="color: #ff8e00 !important;">:</div>
          </template>
          <div>
            <div class="text-3xl font-bold" style="color: #ff8e00 !important;">
              {{ pad(hoursToDday) }}
            </div>
            <small>ชั่วโมง</small>
          </div>
          <div class="text-3xl" style="color: #ff8e00 !important;">:</div>

          <div>
            <div class="text-3xl font-bold" style="color: #ff8e00 !important;">
              {{ pad(minutesToDday) }}
            </div>
            <small>นาที</small>
          </div>
          <div class="text-3xl" style="color: #ff8e00 !important;">:</div>

          <div>
            <div class="text-3xl font-bold" style="color: #ff8e00 !important;">
              {{ pad(secondsToDday) }}
            </div>
            <small>วินาที</small>
          </div>
        </div>
      </div>
    </div>
    </div>
  </Layout>
</template>

<script>
import OrderService from "../core/services/order.service";
const order = new OrderService();

export default {

  name: "expiryComp",
  props: ["data"],
  mounted() {
    this.form = this.data;

     this.interval = setInterval(() => {
      this.getTimeDifference();
    }, 1000);

  },
  methods: {
    pad(n) {
      return n < 10 ? "0" + n : n;
    },
    getTimeDifference() {
      const dDay = new Date(Math.floor(this.form.expiryTimestamp * 1000));
      this.countdownTimeDifference = dDay.getTime() - new Date().getTime();
      this.allocateTimeUnits(this.countdownTimeDifference);
    },
    allocateTimeUnits(timeDifference) {
      const secondsCal = Math.floor(
        (timeDifference / this.milliSecondsInASecond) % this.SecondsInAMinute
      );
      const minutesCal = Math.floor(
        (timeDifference / (this.milliSecondsInASecond * this.minutesInAnHour)) %
          this.SecondsInAMinute
      );
      const hoursCal = Math.floor(
        (timeDifference /
          (this.milliSecondsInASecond *
            this.minutesInAnHour *
            this.SecondsInAMinute)) %
          this.hoursInADay
      );
      const daysCal = Math.floor(
        timeDifference /
          (this.milliSecondsInASecond *
            this.minutesInAnHour *
            this.SecondsInAMinute *
            this.hoursInADay)
      );
      this.secondsToDday = secondsCal < 0 ? 0 : secondsCal;
      this.minutesToDday = minutesCal < 0 ? 0 : minutesCal;
      this.hoursToDday = hoursCal < 0 ? 0 : hoursCal;
      this.daysToDday = daysCal < 0 ? 0 : daysCal;
      if (timeDifference < 0) {
        console.log("aaaa")
        clearInterval(this.interval)
        order.cancelOrder(this.form.orderType,this.form.compId,this.form.orderId,this.form.salepageId).then(
            () =>  {window.location.reload()}
        )
      }

    },
  },
  data() {
    return {
      interval:null,
      timeDifference: 0,
      secondsToDday: 0,
      minutesToDday: 0,
      hoursToDday: 0,
      daysToDday: 0,
      form: null,
      milliSecondsInASecond: 1000,
      hoursInADay: 24,
      minutesInAnHour: 60,
      SecondsInAMinute: 60,
    };
  },
};
</script>
