
import ShortUniqueId from "short-unique-id";
import FileService from "../core/services/file.service";
import { defineComponent } from "vue";

const _fileUploadService = new FileService();
export default defineComponent({
  name: "fileUpload",
  data() {
    return {
      selFile: "",
      fileTypeUpload: ["jpg", "png", "jpeg"],
    };
  },
  methods: {
    fileBrowseHandler(e: any) {
      this.prepareFilesList(e.files);
    },
    async prepareFilesList(files: any) {
      console.log(Object.values(files));
      const file: any = Object.values(files)[0];
      const extension = this.getFileExtension(file.name);
      const uid = new ShortUniqueId();
      const fileName = `public/upload/${uid()}-${+new Date()}.${extension}`;

      this.$emit("file-name", file.name);

      if (
        ![...this.fileTypeUpload].includes(extension.toLowerCase()) ||
        file.size >= 2000000
      )
        return this.$emit("upload-file", null);

      const uploadURL = await _fileUploadService
        .postFile({
          fileName,
          fileType: file.type,
        })
        .then((res) => res.data.uploadURL);
      console.log({ uploadURL });
      await _fileUploadService
        .putFile({
          uploadURL,
          file,
        })
        .then(() => this.onUploaded(fileName));
    },
    getFileExtension(filename: any) {
      return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    },
    onUploaded(s3Key: any) {
      this.selFile = `${process.env.VUE_APP_S3_BLOB_URL}/${s3Key}`;
      console.log("=== s3Key ===", s3Key);
      console.log(s3Key);
      this.$emit("upload-file", s3Key);
    },
    clickFile() {
      const ref = this.$refs as any;
      ref.file.click();
    },
    deleteUrl() {
      this.selFile = "";
    },
  },
});
