
import OrderService from "../../core/services/order.service";
import { defineComponent } from "vue";
import { GappApi } from "@geeesy/type-interfaces";

const order = new OrderService();
export default defineComponent({
  name: "shippingConfirmDialog",
  data() {
    return {
      saveDisabled: false,
      showModal: false,
      showError: false,
      data: Object as unknown as GappApi.OrderLink,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    openDialog(data: any) {
      //เอาไว้ trigger เพื่อเปิด dialog จากหน้าอื่น
      this.data = data; // เก็บค่าไว้ใช้ใน dialog
      this.toggleModal();
    },
    reload() {
      window.location.reload();
    },
    async btnOkClick() {
      try {
        this.saveDisabled = true;
        const compId = this.data.compId;
        const orderId = this.data.orderId;

        const query = this.$route.query;
        const keyQuery = Object.keys(this.$route.query);
        const saleChannelSourceUTM: any = query[keyQuery[0]]
          ? query[keyQuery[0]]
          : "";
        const saleChannelCustomUTM: any = {
          utmName: keyQuery[1] ? keyQuery[1].split("utm_")[1] : "",
          utmValue: query[keyQuery[1]] ? query[keyQuery[1]] : "",
        };

        const newData: GappApi.ConfirmOrderLink & {
          businessId: string;
        } = {
          OrderStatusDate: this.data.OrderStatusDate,
          iamUserId: this.data.iamUserId,
          accountingData: this.data.accountingData,
          shippingConfirmedId: this.data.shippingConfirmedId,
          paymentConfirmedId: "",
          shippingConfirmedData: this.data.shippingConfirmedData,
          paymentConfirmedData: null,
          isNewContactAddress: false,
          paymentAttachmentData: null,
          shippingTrackingData: null,
          customerContactInfo: this.data.customerContactInfo,
          customerCompanyContactInfo: this.data.customerCompanyContactInfo,
          customerNote: "",
          customerId: "",
          businessId: this.data.businessId,
          saleChannelSourceUTM: saleChannelSourceUTM,
          saleChannelCustomUTM: saleChannelCustomUTM,
          latestNoteOnAction: this.data.latestNoteOnAction,
          latestActivityOnOrderType: this.data.latestActivityOnOrderType,
          // latestActionBy: this.data.latestActionBy,
          // latestActionByFullName: this.data.latestActionByFullName,
          latestActionBy: '',
          latestActionByFullName: '',
          latestEntityOnAction: this.data.latestEntityOnAction,
          partnerId: this.data.partnerId,
          campaignId: this.data.campaignId,
          // facebookUserId: '',
          // facebookPages:[]
        };
        await order
          .updateDetailOnlineOrder(compId, orderId, newData)
          .then(() => window.location.reload());
        this.toggleModal();
      } catch (error: any) {
        console.log({ error });
        this.saveDisabled = false;

        if (
          error.response.data.message === "Confirmed shipping is not available"
        ) {
          console.log("Confirmed shipping is not available");

          this.showError = true;
        } else {
          this.toggleModal();
        }
        console.error("ERROR UPDATE ORDER:" + error);
      }
    },
  },
});
