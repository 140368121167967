
// import OrderService from "../core/services/order.service";
import shippingConfirmDialog from "../components/dialogs/shipping-confirmation.vue";
import ThailandAutoComplete from "./vue-thailand-address-autocomplete.vue";
import { defineComponent } from "vue";
import { GappApi, GappSetting } from "@geeesy/type-interfaces";
import { PropType } from "vue";
import {
  IOrderAccounting,
  IOrderProductRow,
  StatusOrderCustomer,
} from "@geeesy/type-interfaces/lib/type-order";
import {
  IShippingMethodTypeBasedByOrderPrice,
  IShippingMethodTypeBasedByPcs,
  IShippingMethodTypeBasedByStepPcs,
  IShippingMethodTypeBasedByStepWeight,
  IShippingMethodTypeFixedRate,
} from "@geeesy/type-interfaces/lib/type-shipping";
import { IAddress } from "@geeesy/type-interfaces/lib/type-company";
import FuncService from "@/core/services/func.service";
// const order = new OrderService();
export default defineComponent({
  name: "compShipping",
  components: {
    shippingConfirmDialog,
    ThailandAutoComplete,
  },
  mounted() {
    this.dataDetail = this.data;
    // มีขนส่งอันเดียว
    if (this.data?.shippingAvailableData.length == 1) {
      this.inpRadShipping =
        this.data?.shippingAvailableData[0].shippingMethodId;
      this.dataSelectShipping = this.data?.shippingAvailableData[0];
      this.triggerSelectShipping();
    }
    if (this.data?.hasNoShipment) {
      const accountingData = this.dataDetail
        ?.accountingData as IOrderAccounting;
      this.dataSelectShipping = null;
      accountingData.shippingCost = 0;
    }
    // if (
    //   this.data?.statusOrderOnBuyer !== StatusOrderCustomer.B1_AwaitingConfirm
    // ) {
    console.log("email => ", this.data?.customerContactInfo);
    // แยก fullName ออกมาเป็น firstname lastname
    // pathValue ลง form
    const name = this.data!.customerContactInfo.fullName.split(" ");
    this.formShipping.firstname.value = name[0] || "";
    this.formShipping.lastname.value = name[1] || "";
    this.formShipping.address.value =
      this.data?.customerContactInfo.shippingAddress![0].addrFull || "";
    this.formShipping.postalcode.value =
      this.data?.customerContactInfo.shippingAddress![0].postcode || "";
    this.formShipping.phone.value = this.checkIsArray(
      this.data?.customerContactInfo.shippingAddress![0].mobiles
    );
    this.formShipping.province.value =
      this.data?.customerContactInfo.shippingAddress![0].province || "";
    this.formShipping.tambon.value =
      this.data?.customerContactInfo.shippingAddress![0].tambon || "";
    this.formShipping.amphoe.value =
      this.data?.customerContactInfo.shippingAddress![0].amphoe || "";
    this.formShipping.email.value = this.checkIsArray(
      this.data?.customerContactInfo.shippingAddress![0].emails
    );
    // }
  },
  data() {
    return {
      func_service: new FuncService(),
      envMedia: process.env.VUE_APP_S3_MEDIA_URL,
      prefixUrlImage: `${process.env.VUE_APP_S3_MEDIA_URL}/public/`,
      StatusOrderCustomer: StatusOrderCustomer,
      dataSelectShipping: Object as unknown as GappSetting.ShippingMethod | any,
      checkSelectShipping: false,
      dataDetail: Object as unknown as GappApi.OrderLink | undefined,
      inpRadShipping: "",
      breakForeach: false,
      errors: Array as any,
      formShipping: {
        firstname: {
          value: "",
          error: "",
        },
        lastname: {
          value: "",
          error: "",
        },
        postalcode: {
          value: "",
          error: "",
        },
        phone: {
          value: "",
          error: "",
        },
        address: {
          value: "",
          error: "",
        },
        province: {
          value: "",
          error: "",
        },
        tambon: {
          value: "",
          error: "",
        },
        amphoe: {
          value: "",
          error: "",
        },
        email: {
          value: "",
          error: "",
        },
      } as any,
    };
  },
  props: {
    data: Object as PropType<GappApi.OrderLink>,
  },
  methods: {
    select(address) {
      // ทำงานเมื่อมีการ select ตำบล อำเภอ จังหวัด เพื่อ pathValue ลง form
      if (address.district) {
        this.formShipping.tambon.value = address.district;
      }
      if (address.amphoe) {
        this.formShipping.amphoe.value = address.amphoe;
      }
      if (address.province) {
        this.formShipping.province.value = address.province;
      }
      if (address.zipcode) {
        this.formShipping.postalcode.value = address.zipcode;
      }
      if (address.district) {
        this.checkFromAddress();
      }
    },
    changeAddress(event, from) {
      // ทำงานเมื่อมีการ change ตำบล อำเภอ จังหวัด เพื่อ pathValue ลง form
      console.log({ from });
      from.value = event.target.value;
      console.log({ from });
      this.checkFromAddress();
    },
    checkFromAddress() {
      // check validate form
      if (
        this.formShipping.tambon.value === "" &&
        this.func_service.validatePickup(this.dataSelectShipping, this.data)
      ) {
        this.formShipping.tambon.error = "* กรุณากรอกตำบล";
        this.errors.push("* กรุณากรอกตำบล");
      } else {
        this.formShipping.tambon.error = "";
      }

      if (
        this.formShipping.amphoe.value === "" &&
        this.func_service.validatePickup(this.dataSelectShipping, this.data)
      ) {
        this.formShipping.amphoe.error = "* กรุณากรอกอำเภอ";
        this.errors.push("* กรุณากรอกอำเภอ");
      } else {
        this.formShipping.amphoe.error = "";
      }

      if (
        this.formShipping.province.value === "" &&
        this.func_service.validatePickup(this.dataSelectShipping, this.data)
      ) {
        this.formShipping.province.error = "* กรุณากรอกจังหวัด";
        this.errors.push("* กรุณากรอกจังหวัด");
      } else {
        this.formShipping.province.error = "";
      }

      if (
        this.formShipping.postalcode.value === "" &&
        this.func_service.validatePickup(this.dataSelectShipping, this.data)
      ) {
        this.formShipping.postalcode.error = "* กรุณากรอกรหัสไปรษณีย์";
        this.errors.push("* กรุณากรอกรหัสไปรษณีย์");
      } else {
        this.formShipping.postalcode.error = "";
      }
    },
    triggerSelectShipping() {
      //สั่ง click element นั้น
      console.log("triggerSelectShipping");
      console.log(this.inpRadShipping);
      const elementt = document.getElementById(this.inpRadShipping);
      console.log({ elementt });
      elementt?.click();
    },
    selectShipping(
      shippingId,
      itemShipping: GappSetting.ShippingMethod,
      datadetail: GappApi.OrderLink
    ) {
      this.inpRadShipping = shippingId;
      this.data!.shippingConfirmedData = itemShipping;
      this.dataSelectShipping = itemShipping;
      this.checkSelectShipping = false;

      const accountingData = this.dataDetail
        ?.accountingData as IOrderAccounting;
      console.log({ accountingData });

      console.log("dataSelectShipping =>", this.dataSelectShipping);
      let productQty = 0;
      //ลูปเพื่อหาจำนวนสินค้าทั้งหมด
      this.dataDetail?.products.forEach(
        (el: IOrderProductRow) => (productQty += +el.productQty)
      );
      if (this.dataSelectShipping.shippingMethodType == "free") {
        // ANCHOR: Type ฟรี ไม่เก็บค่าจัดส่ง
        console.log("Type ฟรี ไม่เก็บค่าจัดส่ง");
        accountingData.shippingCost = 0;
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (itemShipping.shippingMethodType == "pickup") {
        //ANCHOR: Type ฟรี ไม่เก็บค่าจัดส่ง
        console.log("Type รับที่ร้าน");
        accountingData.shippingCost = 0;
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (itemShipping.shippingMethodType == "fixedRate") {
        //ANCHOR: Type กำหนดค่าจัดส่งวัสดุแบบคงที่
        console.log("Type fixedRate");
        const dataSelectShipping = this.dataSelectShipping
          .shippingMethodAttribute as IShippingMethodTypeFixedRate;
        if (this.chkSpecialConditionsShipping(dataSelectShipping)) {
          // ส่งฟรี
          accountingData.shippingCost = 0;
        } else {
          // คิดค่าจัดส่ง
          accountingData.shippingCost = +dataSelectShipping.shippingCost;
        }
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (itemShipping.shippingMethodType == "basedByPcs") {
        //ANCHOR: Type คิดตามจำนวนสินค้า (คิดเป็นชิ้น)
        console.log("Type คิดตามจำนวนสินค้า (คิดเป็นชิ้น)");
        const dataSelectShipping = this.dataSelectShipping
          .shippingMethodAttribute as IShippingMethodTypeBasedByPcs;
        if (this.chkSpecialConditionsShipping(dataSelectShipping)) {
          // ส่งฟรี
          accountingData.shippingCost = 0;
        } else {
          // คิดค่าจัดส่ง
          if (+productQty > 1) {
            // ชิ้นถัดไปราคา...
            accountingData.shippingCost =
              (+dataSelectShipping.shippingCostPerPcs *
                100 *
                (+productQty - 1)) /
                100 +
              +dataSelectShipping.shippingCostFirstPcs;
          } else {
            // ชิ้นแรกราคา...
            accountingData.shippingCost =
              (+dataSelectShipping.shippingCostFirstPcs * 100 * 1) / 100;
          }
        }
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (itemShipping.shippingMethodType == "basedByStepPcs") {
        //ANCHOR: Type คิดตามจำนวนสินค้า
        console.log("Type คิดตามจำนวนสินค้า");
        const dataSelectShipping = this.dataSelectShipping
          .shippingMethodAttribute as IShippingMethodTypeBasedByStepPcs;
        this.breakForeach = false;

        if (this.chkSpecialConditionsShipping(dataSelectShipping)) {
          // ส่งฟรี
          accountingData.shippingCost = 0;
        } else {
          // คิดค่าจัดส่ง
          const notExceedSort = dataSelectShipping.shippingCost;
          console.log("notExceedSort", notExceedSort);

          if (notExceedSort) {
            notExceedSort.forEach((el, index) => {
              console.log("el", el, index);
              if (!this.breakForeach) {
                if (notExceedSort.length - 1 == index) {
                  // ตัวสุดท้ายของ array จะเข้าเงื่อนไขนี้
                  if (+productQty > el.minPcs) {
                    accountingData.shippingCost = +el.shippingCost;
                    this.breakForeach = true;
                  }
                } else {
                  if (+productQty <= el.minPcs) {
                    accountingData.shippingCost = +el.shippingCost;
                    this.breakForeach = true;
                  }
                }
              }
            });
          }
        }
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (itemShipping.shippingMethodType == "basedByStepWeight") {
        //ANCHOR: Type คิดตามน้ำหนักรวมของสินค้า
        console.log("Type คิดตามน้ำหนักรวมของสินค้า");
        const dataSelectShipping = this.dataSelectShipping
          .shippingMethodAttribute as IShippingMethodTypeBasedByStepWeight;
        // ค้างไว้ก่อน ตอนสร้าง Order จาก Biz ยังไม่มี productPackage และต้องไปเพิ่ม ตัวแปร productPackage ใน API ด้วย
        // this.detailProduct = product

        let weightCalUnit = 0; //รวมน้ำหนักของ product
        datadetail.products.forEach((product: IOrderProductRow) => {
          weightCalUnit +=
            +product.productWeight * 100 * (+product.productQty / 100);
        });

        console.log("weightCalUnit", weightCalUnit);
        const notExceedSort = dataSelectShipping.shippingCost;
        this.breakForeach = false;

        if (this.chkSpecialConditionsShipping(dataSelectShipping)) {
          // ส่งฟรี
          accountingData.shippingCost = 0;
        } else {
          // คิดค่าจัดส่ง
          console.log("notExceedSort", notExceedSort);

          if (notExceedSort) {
            notExceedSort.forEach((el, index) => {
              console.log("el", el, index);
              if (!this.breakForeach) {
                if (notExceedSort.length - 1 == index) {
                  // ตัวสุดท้ายของ array จะเข้าเงื่อนไขนี้
                  if (+weightCalUnit > el.minWeight) {
                    accountingData.shippingCost = +el.shippingCost;
                    this.breakForeach = true;
                  }
                } else {
                  if (+weightCalUnit <= el.minWeight) {
                    accountingData.shippingCost = +el.shippingCost;
                    this.breakForeach = true;
                  }
                }
              }
            });
          }
        }
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (itemShipping.shippingMethodType == "basedByOrderPrice") {
        //ANCHOR: Type คิดตามราคาของสินค้าหลังหักส่วนลดแล้ว
        console.log("Type คิดตามราคาของสินค้าหลังหักส่วนลดแล้ว");
        const dataSelectShipping = this.dataSelectShipping
          .shippingMethodAttribute as IShippingMethodTypeBasedByOrderPrice;
        const notExceedSort = dataSelectShipping.shippingCost;
        this.breakForeach = false;
        console.log("notExceedSort", notExceedSort);

        if (notExceedSort) {
          notExceedSort.forEach((el, index) => {
            console.log("el", el, index);
            if (!this.breakForeach) {
              if (notExceedSort.length - 1 == index) {
                // ตัวสุดท้ายของ array จะเข้าเงื่อนไขนี้
                if (+accountingData.subtotalAmount > el.minOrderTotalAmount) {
                  accountingData.shippingCost = +el.shippingCost;
                  this.breakForeach = true;
                }
              } else {
                if (+accountingData.subtotalAmount <= el.minOrderTotalAmount) {
                  accountingData.shippingCost = +el.shippingCost;
                  this.breakForeach = true;
                }
              }
            }
          });
        }
        console.log("Update After SelectShipping", this.dataDetail);
      }

      // ราคารวมหลังหักส่วนลด + ค่าขนส่ง
      accountingData.grandTotalAmount =
        accountingData.subtotalAmount -
        +accountingData.discount +
        +accountingData.shippingCost;

    //NOTE Vat Edit ยอดรวมก่อนภาษี
    if (accountingData.isVatInc || accountingData.vat !== 0) {
      if (accountingData.isVatInc) {
        console.log('netAmount isVatInc')
        // Update Vat percent Doc cal
        // accountingData.vat = (accountingData.grandTotalAmount * 7) / 107
        accountingData.vat = +(
          (accountingData.grandTotalAmount * accountingData.vatPercentage) /
          (100 + accountingData.vatPercentage)
        ).toFixed(2)
        accountingData.netAmount = accountingData.grandTotalAmount - accountingData.vat
      } else {
        // Update Vat percent Doc cal
        // accountingData.vat = (accountingData.grandTotalAmount * 7) / 100
        accountingData.vat = (accountingData.grandTotalAmount * accountingData.vatPercentage) / 100
        accountingData.netAmount = accountingData.grandTotalAmount
        console.log('netAmount2 not isVatInc')
      }
    } else {
      console.log('netAmount3')
      accountingData.netAmount = 0
      accountingData.vat = 0
    }

    // ยอดรวมทั้งสิ้น
    if (accountingData.isVatInc || accountingData.vat !== 0) {
      if (!accountingData.isVatInc) {
        accountingData.grandTotalAmount = accountingData.netAmount + accountingData.vat
      }
    }

    // ภาษีหัก ณ ที่จ่าย
    if (accountingData.isVatInc || accountingData.vat !== 0) {
      accountingData.withholdingTax =
        (accountingData.netAmount * accountingData.taxWithheldPercent) / 100
    } else {
      accountingData.withholdingTax =
        (accountingData.grandTotalAmount * accountingData.taxWithheldPercent) / 100
    }

    // ยอดที่ต้องชำระ
    if (accountingData.isVatInc || accountingData.vat !== 0) {
      if (accountingData.taxWithheldPercent) {
        accountingData.totalPayAmount =
          accountingData.grandTotalAmount -
          (accountingData.netAmount * accountingData.taxWithheldPercent) / 100
      } else {
        accountingData.totalPayAmount = accountingData.grandTotalAmount
      }
    } else {
      if (accountingData.taxWithheldPercent) {
        accountingData.totalPayAmount =
          accountingData.grandTotalAmount +
          accountingData.vat -
          (accountingData.grandTotalAmount * accountingData.taxWithheldPercent) / 100
      } else {
        accountingData.totalPayAmount = accountingData.grandTotalAmount + accountingData.vat
      }
    }

      // NOTE - Update Vat Functions 30-05-2023 // 
      // //NOTE VAT มี vat ?
      // if (accountingData.vat) {
      //   if (accountingData.isVatInc) {
      //     accountingData.vat = +(
      //       (accountingData.grandTotalAmount * 7) /
      //       107
      //     ).toFixed(2);
      //     accountingData.netAmount = +(
      //       accountingData.grandTotalAmount - accountingData.vat
      //     ).toFixed(2);
      //     accountingData.totalPayAmount = +(
      //       accountingData.grandTotalAmount - accountingData.withholdingTax
      //     ).toFixed(2);
      //   } else {
      //     accountingData.netAmount =
      //       +accountingData.grandTotalAmount.toFixed(2);
      //     accountingData.vat = +((accountingData.netAmount * 7) / 100).toFixed(
      //       2
      //     );
      //     accountingData.grandTotalAmount = +(
      //       accountingData.netAmount + accountingData.vat
      //     ).toFixed(2);
      //     accountingData.totalPayAmount = +(
      //       accountingData.grandTotalAmount - accountingData.withholdingTax
      //     ).toFixed(2);
      //   }
      // } else {
      //   accountingData.totalPayAmount = +(
      //     accountingData.grandTotalAmount - accountingData.withholdingTax
      //   ).toFixed(2);
      // }

    },
    chkSpecialConditionsShipping(dataSelectShipping) {
      //เงื่อนไขพิเศษ จัดส่งฟรี เมื่อราคาของสินค้าหลังหักส่วนลดแล้ว มากกว่า minOrderTotalAmount
      return (
        dataSelectShipping.minOrderTotalAmount &&
        +this.dataDetail!.accountingData.subtotalAmount >
          +dataSelectShipping.minOrderTotalAmount
      );
    },
    watch(txt) {
      this.errors = [];
      if (txt === "firstname") {
        if (this.formShipping.firstname.value === "") {
          this.formShipping.firstname.error = "* กรุณากรอกชื่อจริง";
          this.errors.push("* กรุณากรอกชื่อจริง");
        } else {
          this.formShipping.firstname.error = "";
        }
      }

      if (txt === "address") {
        if (
          this.formShipping.address.value === "" &&
          this.func_service.validatePickup(this.dataSelectShipping, this.data)
        ) {
          this.formShipping.address.error = "* กรุณากรอกที่อยู่";
          this.errors.push("* กรุณากรอกที่อยู่");
        } else {
          this.formShipping.address.error = "";
        }
      }

      if (txt === "phone") {
        if (this.formShipping.phone.value === "") {
          this.formShipping.phone.error = "* กรุณากรอกเบอร์โทร";
          this.errors.push("* กรุณากรอกเบอร์โทร");
        } else {
          this.formShipping.phone.error = "";
        }
      }

      if (txt === "email") {
        this.formShipping.email.error = "";
        if (
          !this.validEmail(this.formShipping.email.value) &&
          this.formShipping.email.value
        ) {
          this.formShipping.email.error = "* กรุณากรอกอีเมลให้ถูกต้อง";
          this.errors.push("* กรุณากรอกอีเมลให้ถูกต้อง");
        }
      }
    },
    submitForm() {
      this.errors = [];
      if (this.formShipping.firstname.value === "") {
        this.formShipping.firstname.error = "* กรุณากรอกชื่อจริง";
        this.errors.push("* กรุณากรอกชื่อจริง");
      } else {
        this.formShipping.firstname.error = "";
      }

      if (
        this.formShipping.address.value === "" &&
        this.func_service.validatePickup(this.dataSelectShipping, this.data)
      ) {
        this.formShipping.address.error = "* กรุณากรอกที่อยู่";
        this.errors.push("* กรุณากรอกที่อยู่");
      } else {
        this.formShipping.address.error = "";
      }

      if (
        this.formShipping.tambon.value === "" &&
        this.func_service.validatePickup(this.dataSelectShipping, this.data)
      ) {
        this.formShipping.tambon.error = "* กรุณากรอกตำบล";
        this.errors.push("* กรุณากรอกตำบล");
      } else {
        this.formShipping.tambon.error = "";
      }

      if (
        this.formShipping.amphoe.value === "" &&
        this.func_service.validatePickup(this.dataSelectShipping, this.data)
      ) {
        this.formShipping.amphoe.error = "* กรุณากรอกอำเภอ";
        this.errors.push("* กรุณากรอกอำเภอ");
      } else {
        this.formShipping.amphoe.error = "";
      }

      if (
        this.formShipping.province.value === "" &&
        this.func_service.validatePickup(this.dataSelectShipping, this.data)
      ) {
        this.formShipping.province.error = "* กรุณากรอกจังหวัด";
        this.errors.push("* กรุณากรอกจังหวัด");
      } else {
        this.formShipping.province.error = "";
      }

      if (
        this.formShipping.postalcode.value === "" &&
        this.func_service.validatePickup(this.dataSelectShipping, this.data)
      ) {
        this.formShipping.postalcode.error = "* กรุณากรอกรหัสไปรษณีย์";
        this.errors.push("* กรุณากรอกรหัสไปรษณีย์");
      } else {
        this.formShipping.postalcode.error = "";
      }

      if (this.formShipping.phone.value === "") {
        this.formShipping.phone.error = "* กรุณากรอกเบอร์โทร";
        this.errors.push("* กรุณากรอกเบอร์โทร");
      } else {
        this.formShipping.phone.error = "";
      }

      this.formShipping.email.error = "";
      if (
        !this.validEmail(this.formShipping.email.value) &&
        this.formShipping.email.value
      ) {
        this.formShipping.email.error = "* กรุณากรอกอีเมลให้ถูกต้อง";
        this.errors.push("* กรุณากรอกอีเมลให้ถูกต้อง");
      }
      console.log("this.errors");
      console.log(this.errors);
      if (!this.errors.length) {
        if (this.dataSelectShipping || this.data?.hasNoShipment) {
          const address = this.dataDetail!.customerContactInfo
            .address as IAddress;
          const addressShipping = this.dataDetail!.customerContactInfo
            .shippingAddress as IAddress[];

          this.dataDetail!.customerContactInfo.fullName =
            this.formShipping.firstname.value +
            " " +
            this.formShipping.lastname.value;

            this.dataDetail!.customerContactInfo.firstName = this.formShipping.firstname.value
            this.dataDetail!.customerContactInfo.lastName = this.formShipping.lastname.value

          this.dataDetail!.customerContactInfo.shippingAddress![0].mobiles = [
            this.formShipping.phone.value.split("-").join(""),
          ];

          (address.addrFull = this.formShipping.address.value),
            (address.province = this.formShipping.province.value);
          address.amphoe = this.formShipping.amphoe.value;
          address.tambon = this.formShipping.tambon.value;
          address.postcode = this.formShipping.postalcode.value;
          address.phones = [this.formShipping.phone.value.split("-").join("")];
          address.emails = [this.formShipping.email.value];

          addressShipping[0].addrFull = this.formShipping.address.value;
          addressShipping[0].province = this.formShipping.province.value;
          addressShipping[0].amphoe = this.formShipping.amphoe.value;
          addressShipping[0].tambon = this.formShipping.tambon.value;
          addressShipping[0].postcode = this.formShipping.postalcode.value;
          addressShipping[0].phones = [
            this.formShipping.phone.value.split("-").join(""),
          ];
          addressShipping[0].emails = [this.formShipping.email.value];

          this.dataDetail!.customerContactInfo.shippingAddress![0].emails = [
            this.formShipping.email.value,
          ];
          this.dataDetail!.customerContactInfo.emails = [
            this.formShipping.email.value,
          ];
          this.dataDetail!.shippingConfirmedData = this.dataSelectShipping;
          this.dataDetail!.shippingConfirmedId = this.inpRadShipping;

          this.dataDetail!.latestNoteOnAction = this.data!.latestNoteOnAction;
          this.dataDetail!.latestActivityOnOrderType =
            this.data!.latestActivityOnOrderType;

          console.log("post to api");
          const ref = this.$refs as any;
          console.log(this.dataDetail);
          console.log("datadatadatadata => ", this.data);

          ref.shippingDialog.openDialog(this.dataDetail);
        }
      } else {
        var elmnt = document.getElementById("shippingForm") as any;
        elmnt.scrollIntoView({ behavior: "smooth" });
      }
    },
    acceptNumber() {
      var x: any = this.formShipping.phone.value
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      console.log(x);
      this.formShipping.phone.value = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
      this.watch("phone");
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkIsArray(val) {
      // เช็คว่าเป็น array ไหม ถ้าใช่จะ get array ที่ 0
      return Array.isArray(val) ? val[0] : val;
    },
    currency(e) {
      return e;
      // return parseFloat(e)
      //   .toFixed(2)
      //   .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
  },
});
