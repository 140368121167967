
import { defineComponent } from 'vue';

export default defineComponent({
  name: "compHeader",
  data() {
    return {
      envMedia: process.env.VUE_APP_S3_MEDIA_URL,
      prefixUrlImage: `${process.env.VUE_APP_S3_BLOB_URL}/public/`,
    };
  },
  props: {
    topic: String,
    heading: String,
    companyLogo: String,
    companyName: String,
    link: String,
    orderId: String,
  },
});
