
import FuncService from "@/core/services/func.service";
import { GappApi } from "@geeesy/type-interfaces";
import { defineComponent } from "vue";
import { PropType } from "vue";

export default defineComponent({
  name: "compProductInfo",
  props: {
    data: Object as PropType<GappApi.OrderLink>,
  },
  data() {
    return {
      envMedia: process.env.VUE_APP_S3_MEDIA_URL,
      func_service: new FuncService(),
      prefixUrlImage: `${process.env.VUE_APP_S3_BLOB_URL}/`,
    };
  },
  methods: {
    sortedArray(arrays) {
      return arrays.sort((a, b) => a.variantId - b.variantId);
    },
    imgUrl(url) {
      if (url.startsWith("public/")) {
        return `${this.prefixUrlImage}${url}`;
      } else {
        return `${this.prefixUrlImage}public/${url}`;
      }
    },
  },
});
