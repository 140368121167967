
import { GappApi } from "@geeesy/type-interfaces";
import { StatusOrderSeller } from "@geeesy/type-interfaces/lib/type-order";
import { defineComponent } from "vue";
import { PropType } from "vue";
import FuncService from "@/core/services/func.service";

export default defineComponent({
  name: "compDetailShipping",
  data() {
    return {
      envMedia: process.env.VUE_APP_S3_MEDIA_URL,
      func_service: new FuncService(),
      StatusOrderSeller: StatusOrderSeller,
      prefixUrlImage: `${process.env.VUE_APP_S3_MEDIA_URL}/public/`,
    };
  },
  props: {
    data: Object as PropType<GappApi.OrderLink>,
  },
  methods: {
    formathDate(timestamp) {
      let d = new Date(timestamp);
      let mm = d.getMonth() + 1;
      let dd = d.getDate();
      let yy = d.getFullYear();
      return `${dd}/${mm}/${yy}`; //(US)
    },
  },
});
