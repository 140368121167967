
import { defineComponent } from "vue";

import paymentConfirmDialog from "../components/dialogs/payment-confirmation.vue";
import { PropType } from "vue";
import { GappApi } from "@geeesy/type-interfaces";
import {
  StatusOrderCustomer,
  StatusOrderSeller,
} from "@geeesy/type-interfaces/lib/type-order";
import OrderService from "../core/services/order.service";
import FuncService from "@/core/services/func.service";
const order = new OrderService();

export default defineComponent({
  name: "compPriceSummary",
  props: {
    data: Object as PropType<GappApi.OrderLink>,
  },
  mounted() {
    if (this.data!.expiryTimestamp && (this.data!.statusOrderOnSeller === StatusOrderSeller.S1_AwaitingConfirm || this.data!.statusOrderOnSeller === StatusOrderSeller.S2_AwaitingPayment)) {
      setInterval(() => {
        this.chkExpiryDate();
      }, 1000);
    }

  },
  data() {
    return {
      func_service: new FuncService(),
      prefixUrlImage: `${process.env.VUE_APP_S3_BLOB_URL}/`,
      StatusOrderSeller: StatusOrderSeller,
      StatusOrderCustomer: StatusOrderCustomer,
      chkExpiry: true
    };
  },
  components: {
    paymentConfirmDialog,
  },
  methods: {
    openDialogConfirmpayment(data: any) {
      const route = this.$route as any;
      order.getByShortOrderId(route.params.shortOrderId).then((short) => {
        console.log({ short });
        const shortData = short.data.data
        console.log({ shortData });


        order
          .getDetailOnlineOrder(
            shortData.compId,
            shortData.orderId,
            route.params.type,
            shortData.salepageId
          )
          .then((response) => {
            if (
              response.data.data.statusOrderOnSeller !=
              this.StatusOrderSeller.S5_AwaitingShipment
            ) {
              const ref = this.$refs as any;
              ref.paymentConfirm.openDialog(data);
            } else {
              window.location.reload();
            }
          });
      })
    },
    convertDate(date) {
      const dateMe = new Date(date);
      console.log({ date });
      console.log({ dateMe });

      const day = ("0" + (dateMe.getDate())).slice(-2);
      const month = ("0" + (dateMe.getMonth() + 1)).slice(-2);
      const year = dateMe.getFullYear();
      return `${day}/${month}/${year}`;
    },
    imgUrl(url) {
      if (url.startsWith("public/")) {
        return `${this.prefixUrlImage}${url}`;
      } else {
        return `${this.prefixUrlImage}public/${url}`;
      }
    },
    chkExpiryDate() {
      const expiryDate = Math.floor(this.data!.expiryTimestamp * 1000)
      const dateNow = new Date().getTime()
      this.chkExpiry = dateNow < expiryDate
    }
  },
});
