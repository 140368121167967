import "./index.css";

import App from "./App.vue";
import { createApp } from "vue";
import router from "./router";
import PrimeVue from "primevue/config";

// Import the CSS styles
import "primevue/resources/themes/saga-blue/theme.css"; // Choose a theme that you like
import "primevue/resources/primevue.min.css"; // Core styles
import "primeicons/primeicons.css"; // PrimeIcons (optional)
import Calendar from "primevue/calendar";

// createApp(App).use(router).use(PrimeVue).mount("#app");
const app = createApp(App)
app.use(router)
app.use(PrimeVue)

app.component('Calendar', Calendar);

app.mount("#app")