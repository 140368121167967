
import OrderService from "../../core/services/order.service";
import fileUpload from "../file-upload.component.vue";
import { defineComponent } from "vue";
import { GappApi, GappBiz, GappSetting } from "@geeesy/type-interfaces";
import CurrencyInput from "../input-number.component.vue";
import { StatusOrderSeller } from "@geeesy/type-interfaces/lib/type-order";
import {
  BankList,
  BankAccountTypeArr,
} from "@geeesy/type-interfaces/lib/enum-const";

import "@fortawesome/fontawesome-free/css/all.css";

const order = new OrderService();
export default defineComponent({
  name: "paymentConfirmDialog",
  components: {
    fileUpload,
    CurrencyInput,
  },
  data() {
    return {
      fileName: "",
      isSelectTypePayment: "bankDeposit",
      StatusOrderSeller: StatusOrderSeller,
      saveDisabled: false,
      paymentPrice: 0 as number,
      note: "",
      envMedia: process.env.VUE_APP_S3_MEDIA_URL,
      prefixUrlImage: `${process.env.VUE_APP_S3_BLOB_URL}/`,
      dialogCopyArr: [] as any,
      checkSelectPayment: false,
      dataitempayment: null as unknown as GappSetting.PaymentMethod,
      checkUploadimg: false,
      checkUploadimgType: false,
      crtlTimeChk: false,
      crtlDateChk: false,
      paymentPriceChk: false,
      dataslipimageUrl: "",
      selPayment: "",
      showModal: false,
      data: Object as unknown as GappApi.OrderLink,
      crtlDate: order.getDateNowToISOString(),
      crtlTime: `${
        (new Date().getHours() < 10 ? "0" : "") + new Date().getHours()
      }:${(new Date().getMinutes() < 10 ? "0" : "") + new Date().getMinutes()}`,
      selectedDate: new Date(),
      selectedTime: new Date(),
      errors: Array as any,
      channelsList: Array as any,
      paymentList: "" as any,
      currentChannels: "" as any,
      promptPayQrCodeImg: "" as any,
      amount: null as any,
    };
  },
  methods: {
    toggleModal() {
      if (this.data.paymentAvailableData.length == 1) {
        this.selPayment = this.data?.paymentAvailableData[0].paymentMethodId;
        this.dataitempayment = this.data?.paymentAvailableData[0];
      }
      this.showModal = !this.showModal;

      console.log("showModal :: =>", this.showModal);
      if (!this.showModal) {
        this.selPayment = "";
      }
    },
    copyLink(data: any) {
      console.log(data);
      this.dialogCopyArr.push(true);
      navigator.clipboard.writeText(data.paymentMethodAttribute.accountNo);
      setTimeout(() => {
        this.dialogCopyArr.shift();
      }, 1000);
    },
    openDialog(data: any) {
      //เอาไว้ trigger เพื่อเปิด dialog จากหน้าอื่น
      this.data = data;
      this.isSelectTypePayment = data.paymentAvailableData.filter(
        (res) =>
          res.paymentMethodType === "bankDeposit" ||
          res.paymentMethodType === "promptPay"
      ).length
        ? "bankDeposit"
        : "cash";

      console.log("case ---1 :: =>", this.isSelectTypePayment);

      this.paymentPrice = this.data.accountingData.totalPayAmount;
      this.toggleModal();
      this.selectChannel(data.paymentAvailableData, this.isSelectTypePayment);
    },
    deleteUrlImage() {
      this.dataslipimageUrl = "";
      this.fileName = "";
    },
    selectBank(inpRad: string, itempayment: any) {
      this.selPayment = inpRad;
      this.dataitempayment = itempayment;
      this.checkSelectPayment = false;
      // data :: order detail
      if (itempayment.paymentMethodType === "promptPay") {
        const orderDetail = this.data;
        this.genPromptPayQrCode(orderDetail.totalOrderAmount, itempayment);
      }
    },
    getFileName(name) {
      this.fileName = name;
    },
    getLogoUrl(url: string) {
      console.log({ url });
      if (url) {
        this.dataslipimageUrl = url;
        this.checkUploadimg = false;
        this.checkUploadimgType = false;
      } else {
        this.checkUploadimgType = true;
      }
    },
    paymentNumber(e) {
      let number = e;
      return `${number.slice(0, 3)}-${number.slice(3, 4)}-${number.slice(
        4,
        9
      )}-${number.slice(9)}`;
    },
    promptPayNumber(value, type) {
      if (type === "IDENTITYID") {
        return value.replace(
          /(\d{1})\D?(\d{4})\D?(\d{5})\D?(\d{2})\D?(\d{1})/,
          "$1-$2-$3-$4-$5"
        );
      } else if (type === "MOBILE") {
        return value.replace(/(\d{3})\D?(\d{3})\D?(\d{4})/, "$1-$2-$3");
      } else if (type === "EWALLET") {
        return value.replace(
          /(\d{3})\D?(\d{1})\D?(\d{5})\D?(\d{6})/,
          "$1-$2-$3-$4"
        );
      }
    },

    validator() {
      console.log("validator");
      this.errors = [];
      if (this.data.paymentAvailableData.length == 1) {
        this.dataitempayment = this.data.paymentAvailableData[0];
      }
      // Set Date And time //
      this.crtlDate = order.convertDateToISOString(this.selectedDate);
      this.crtlTime = order.convertTimeToISOString(this.selectedTime);
      // -------------------- //
      console.log("this.dataitempayment" + this.dataitempayment);
      console.log("this.dataslipimageUrl" + this.dataslipimageUrl);
      console.log("this.crtlDate" + this.crtlDate);
      console.log("this.crtlTime" + this.crtlTime);

      if (!this.dataitempayment) {
        this.checkSelectPayment = true;
        this.errors.push(true);
      }

      if (!this.dataslipimageUrl) {
        this.checkUploadimg = true;
        this.errors.push(true);
      }

      if (!this.paymentPrice) {
        this.paymentPriceChk = true;
        this.errors.push(true);
      } else {
        this.paymentPriceChk = false;
      }

      if (!this.crtlDate) {
        this.crtlDateChk = true;
        this.errors.push(true);
      } else {
        this.crtlDateChk = false;
      }

      if (!this.crtlTime) {
        this.crtlTimeChk = true;
        this.errors.push(true);
      } else {
        this.crtlTimeChk = false;
      }
    },
    convertBankName(name) {
      return BankList[name.toUpperCase()].official_name_thai
        .split("ธนาคาร")
        .join("");
    },
    convertTypePayment(name) {
      const data = BankAccountTypeArr.find(
        (val: any) => val.value == name
      ) as any;
      return data.text.split("บัญชี").join("");
    },
    btnOkClick() {
      console.log('checkSelectPayment :: =>',this.checkSelectPayment);
      
      const route = this.$route as any;

      console.log("this.dataslipimageUrl :: =>", this.dataitempayment);

      order.getByShortOrderId(route.params.shortOrderId).then((short) => {
        console.log({ short });
        const shortData = short.data.data;
        console.log({ shortData });

        order
          .getDetailOnlineOrder(
            shortData.compId,
            shortData.orderId,
            route.params.type,
            shortData.salepageId
          )
          .then((response) => {
            if (
              response.data.data.statusOrderOnSeller !=
              this.StatusOrderSeller.S5_AwaitingShipment
            ) {
              this.validator();
              if (!this.errors.length) {
                try {
                  this.saveDisabled = true;
                  // let hours = `${this.crtlTime.value.hours}`;
                  // let minutes = `${this.crtlTime.value.minutes}`;

                  // let date = new Date();
                  // date.setDate(this.crtlDate.value.day);
                  // date.setMonth(this.crtlDate.value.month);
                  // date.setFullYear(this.crtlDate.value.year);

                  // console.log(date);

                  // console.log(hours, minutes);

                  // if (hours.length == 1) {
                  //   hours = "0" + hours;
                  //   console.log(hours);
                  // }

                  // if (minutes.length == 1) {
                  //   minutes = "0" + minutes;
                  //   console.log(minutes);
                  // }

                  // this.data.buyerpayment.payment = this.dataitempayment;
                  // this.data.buyerpayment.slipimageUrl = this.dataslipimageUrl;
                  // this.data.buyerpayment.timecreatedAt = this.crtlTime || "0:0";
                  // this.data.buyerpayment.paymentcreatedAt = this.crtlDate;

                  // this.data.status.seller = "checkpayment";
                  // this.data.status.buyer = "waitprocess";
                  const route = this.$route as any;
                  // const newData: GappApi.ConfirmOrderLink = {
                  //   OrderStatusDate: this.data.OrderStatusDate,
                  //   facebookUserId: this.data.facebookUserId,
                  //   facebookPages: this.data.facebookPages,
                  //   iamUserId: this.data.iamUserId,
                  //   accountingData: this.data.accountingData,
                  //   shippingConfirmedId: this.data.shippingConfirmedId,
                  //   paymentConfirmedId: this.selPayment,
                  //   shippingConfirmedData: this.data.shippingConfirmedData,
                  //   paymentConfirmedData: this.dataitempayment,
                  //   isNewContactAddress: false,
                  //   paymentAttachmentData: {
                  //     paymentImageUrl: this.dataslipimageUrl,
                  //     paymentTime: this.crtlTime,
                  //     paymentDate: this.crtlDate,
                  //     paymentPrice: +this.paymentPrice,
                  //     shippingCost: this.data.accountingData.shippingCost,
                  //     note: this.note,
                  //   },
                  //   shippingTrackingData: null,
                  //   customerContactInfo: this.data.customerContactInfo,
                  //   customerCompanyContactInfo:
                  //     this.data.customerCompanyContactInfo,
                  //   customerNote: "",
                  //   customerId: this.data.customerId,
                  //   saleChannelSourceUTM: this.data.saleChannelSourceUTM,
                  //   saleChannelCustomUTM: this.data.saleChannelCustomUTM,
                  //   latestNoteOnAction: this.data.latestNoteOnAction,
                  //   latestActivityOnOrderType:
                  //     this.data.latestActivityOnOrderType,
                  //   latestActionBy: "",
                  //   latestActionByFullName: this.data.customerContactInfo.fullName,
                  //   latestEntityOnAction: this.data.latestEntityOnAction,
                  //   partnerId: this.data.partnerId,
                  //   campaignId: this.data.campaignId,
                  // };

                  const newData: GappBiz.CreatePaymentRowInOrder = {
                    isPaymentRowSlipAutomatic: false,
                    paymentRowWarning: null,
                    paymentRowWarningLevel: null,
                    customerFullName: this.data.customerContactInfo.fullName,
                    docNo: this.data.docNo,
                    hasOverrideEmailSending: false,
                    productImageFirstUrl: this.data.productImageFirstUrl,
                    productSkuFirst: this.data.productSkuFirst,
                    productTitleFirst: this.data.productTitleFirst,
                    orderId: this.data.orderId,
                    paymentConfirmedData: this.dataitempayment,
                    paymentAttachmentData: {
                      paymentImageUrl: this.dataslipimageUrl,
                      paymentTime: this.crtlTime,
                      paymentDate: this.crtlDate,
                      paymentPrice: +this.paymentPrice,
                      shippingCost: this.data.accountingData.shippingCost,
                      note: this.note,
                    },
                    identityData: null,
                    isAutoConfirmSlip: false,
                    paymentRowStatus: "WAIT",
                    businessId: this.data.businessId,
                    compId: this.data.compId,
                    identityId: "",
                    identityFullName: this.data.customerContactInfo.fullName,
                    companyCode: "",
                    salepageId: this.data.salepageId || "",
                    orderType: this.data.orderType,
                  };

                  console.log("this.data");
                  console.log(this.data);
                  const compId = this.data.compId;
                  const orderId = this.data.orderId;
                  order
                    .getByShortOrderId(route.params.shortOrderId)
                    .then((short) => {
                      console.log({ short });
                      const shortData = short.data.data;
                      console.log({ shortData });
                      order
                        .updatePaymentOnlineOrder(
                          compId,
                          orderId,
                          newData,
                          route.params.type,
                          shortData.salepageId
                        )
                        .then(() => window.location.reload());
                    });

                  this.toggleModal();
                } catch (error) {
                  this.saveDisabled = false;
                  console.error("ERROR UPDATE ORDER:" + error);
                }
              }
              console.log(this.data);
            } else {
              window.location.reload();
            }
          });
      });
    },
    hasLength(data, type) {
      if (type === "bankDeposit") {
        return data.filter(
          (res) =>
            res.paymentMethodType === "bankDeposit" ||
            res.paymentMethodType === "promptPay"
        ).length;
      } else {
        return data.filter((res) => res.paymentMethodType === type).length;
      }
    },
    selectChannel(data, type) {
      console.log("selectChannel :: data =>", data);
      console.log("selectChannel :: type =>", type);

      this.currentChannels = type;
      //NOTE filter ลิสช่องทาง
      if (type == "bankDeposit") {
        this.paymentList = data.filter(
          (res) =>
            res.paymentMethodType === "bankDeposit" ||
            res.paymentMethodType === "promptPay"
        );
      } else {
        this.paymentList = data.filter(
          (res) => res.paymentMethodType === "cash"
        );
      }

      console.log("paymentList :: =>", this.paymentList);

      //NOTE กรณีที่ bank และ เงินสด มีอย่างละหนึ่งตัว
      if (this.paymentList.length == 1) {
        this.selPayment = this.paymentList[0].paymentMethodId;
        this.dataitempayment = this.paymentList[0];
      }
    },
    genPromptPayQrCode(roderData, paymentData) {
      console.warn("|||| genPromptPayQrCode :: =>", roderData, paymentData);
      if (roderData && paymentData) {
        const promptPayData: GappBiz.GenQrPromptPay = {
          accountNumber: paymentData.paymentMethodAttribute.accountNo,
          amount: roderData,
        };
        order.genQrCodePromptPay(promptPayData).then((response) => {
          if (response.data) {
            try {
              this.promptPayQrCodeImg = response.data.data;
            } catch (error) {
              console.error("gen qr code error :: =>", error);
            }
          } else {
            console.log("loop else :: =>");
          }
        });
      }
    },
    changePromptPayQrCode() {
      clearTimeout(this.amount);
      this.amount = setTimeout(() => {
        console.log("setTimeout :: hahahha =>", this.paymentPrice);
        this.genPromptPayQrCode(this.paymentPrice, this.dataitempayment);
      }, 1000);
    },
  },
  watch: {
    paymentPrice() {
      this.changePromptPayQrCode();
    },
    selectChannel(data, type) {
      this.currentChannels = type;
      //NOTE filter ลิสช่องทาง
      if (type == "bankDeposit") {
        this.paymentList = data.filter(
          (res) => res.paymentMethodType === "bankDeposit"
        );
      } else {
        this.paymentList = data.filter(
          (res) => res.paymentMethodType === "cash"
        );
      }

      //NOTE กรณีที่ bank และ เงินสด มีอย่างละหนึ่งตัว
      if (this.paymentList.length == 1) {
        this.selPayment = this.paymentList[0].paymentMethodId;
        this.dataitempayment = this.paymentList[0];
      }
    },
  },
});
