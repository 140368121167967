
import FuncService from "@/core/services/func.service";
import { GappApi } from "@geeesy/type-interfaces";
import {
  BankAccountTypeArr,
  BankList,
} from "@geeesy/type-interfaces/lib/enum-const";
import {
  StatusOrderCustomer,
  StatusOrderSeller,
} from "@geeesy/type-interfaces/lib/type-order";
import { IPaymentMethodTypeDeposit } from "@geeesy/type-interfaces/lib/type-payment";
import { defineComponent } from "vue";
import { PropType } from "vue";
import paymentConfirmComp from "./payment-confirmation.component.vue";
import OrderService from "../core/services/order.service";

const order = new OrderService();

export default defineComponent({
  name: "compBill",
  props: {
    data: Object as PropType<GappApi.OrderLink>,
  },
  components: {
    paymentConfirmComp,
  },
  mounted: function () {
    const route = this.$route as any;
    console.log("aaaaaa");
    order.getByShortOrderId(route.params.shortOrderId).then((short) => {
      console.log({ short });
      const shortData = short.data.data;
      console.log({ shortData });
      order.getPaymentRows(shortData.compId, shortData.orderId).then((res) => {
        console.log("bbbbb");
        this.paymentRows = res.data.data;
        console.log(this.paymentRows);
      });
    });
  },
  data() {
    return {
      paymentRows: [],
      envMedia: process.env.VUE_APP_S3_MEDIA_URL,
      func_service: new FuncService(),
      dialogCopyArr: [],
      StatusOrderSeller: StatusOrderSeller,
      StatusOrderCustomer: StatusOrderCustomer,
      prefixUrlImage: `${process.env.VUE_APP_S3_BLOB_URL}/`,
    };
  },
  methods: {
    copyLink(data: any) {
      const copyArr = this.dialogCopyArr as Array<Boolean>;
      copyArr.push(true);
      navigator.clipboard.writeText(data.paymentMethodAttribute.accountNo);
      setTimeout(() => {
        this.dialogCopyArr.shift();
      }, 1000);
    },
    getAttribute(item): IPaymentMethodTypeDeposit {
      return item.paymentMethodAttribute as IPaymentMethodTypeDeposit;
    },
    convertDate(date) {
      const dateMe = new Date(date);
      console.log({ date });
      console.log({ dateMe });

      const day = ("0" + dateMe.getDate()).slice(-2);
      const month = ("0" + (dateMe.getMonth() + 1)).slice(-2);
      const year = dateMe.getFullYear();
      return `${day}/${month}/${year}`;
    },
    convertDateAndTime(date) {
      const dateMe = new Date(date);
      console.log({ date });
      console.log({ dateMe });

      const day = ("0" + dateMe.getDate()).slice(-2);
      const month = ("0" + (dateMe.getMonth() + 1)).slice(-2);
      const year = dateMe.getFullYear();
      const hours = dateMe.getHours();
      const minutes = dateMe.getMinutes();
      return `${day}/${month}/${year} ${hours}:${minutes} น.`;
    },
    imgUrl(url) {
      if (url.startsWith("public/")) {
        return `${this.prefixUrlImage}${url}`;
      } else {
        return `${this.prefixUrlImage}public/${url}`;
      }
    },
    paymentNumber(e) {
      let number = e;
      console.log(number);
      return `${number.slice(0, 3)}-${number.slice(3, 4)}-${number.slice(
        4,
        9
      )}-${number.slice(9)}`;
    },
    promptPayNumber(value, type) {
      if (type === "IDENTITYID") {
        return value.replace(
          /(\d{1})\D?(\d{4})\D?(\d{5})\D?(\d{2})\D?(\d{1})/,
          "$1-$2-$3-$4-$5"
        );
      } else if (type === "MOBILE") {
        return value.replace(/(\d{3})\D?(\d{3})\D?(\d{4})/, "$1-$2-$3");
      } else if (type === "EWALLET") {
        return value.replace(
          /(\d{3})\D?(\d{1})\D?(\d{5})\D?(\d{6})/,
          "$1-$2-$3-$4"
        );
      }
    },
    convertBankName(name) {
      return BankList[name.toUpperCase()].official_name_thai
        .split("ธนาคาร")
        .join("");
    },
    convertTypePayment(name) {
      const data = BankAccountTypeArr.find(
        (val: any) => val.value == name
      ) as any;
      return data.text.split("บัญชี").join("");
    },
    mapListPaymentAvailableData(data) {
      let newDataArr: any = [];

      const filBankDepisit = data.filter(
        (res) =>
          res.paymentMethodType === "bankDeposit" ||
          res.paymentMethodType === "promptPay"
      );
      const filCash = data.filter((res) => res.paymentMethodType === "cash");

      if (filBankDepisit.length > 0) {
        newDataArr.push({ name: "bankDeposit", data: filBankDepisit });
      }

      if (filCash.length > 0) {
        newDataArr.push({ name: "cash", data: filCash });
      }

      console.log("filBankDepisit :: =>", filBankDepisit);
      console.log("filCash :: =>", filCash);

      // data.map((res: any) => {
      //   const find: any = newDataArr.find(
      //     (val: any) => val.name === res.paymentMethodType
      //   );

      //   if (find) {
      //     console.log({ find });
      //     find.data.push(res);
      //   } else {
      //     newDataArr.push({ name: res.paymentMethodType, data: [res] });
      //   }
      // });
      // console.log({ newDataArr });

      return newDataArr;
    },
  },
});
