import OrderService from "./order.service";
import axios from "axios";

const order = new OrderService();
const envName = order.getEnvName === 'test' || order.getEnvName === 'stg' ? '' : `${order.getEnvName}.`
const ROOT_URL = `https://${envName}order.gapp-biz.com/api`;
// const ROOT_URL = `https://feat-promptpay.order.gapp-biz.com/api`;



export default class FileService {
  postFile(file:any) {
    return axios.post(`${ROOT_URL}/image-file-upload`, file);
  }
  putFile(data:any) {
    const { uploadURL, file } = data;
    return axios.put(uploadURL, file);
  }
}
