
import { GappApi } from "@geeesy/type-interfaces";
import {
  StatusOrderCustomer,
  StatusOrderSeller,
} from "@geeesy/type-interfaces/lib/type-order";
import { defineComponent } from "vue";
import { PropType } from "vue";
import FuncService from "@/core/services/func.service";

export default defineComponent({
  name: "compOrderStatus",
  data() {
    return {
      StatusOrderCustomer: StatusOrderCustomer,
      StatusOrderSeller: StatusOrderSeller,
      func_service: new FuncService(),
    };
  },
  methods: {
    formatDate(date){
      const newDate = new Date(date)
      const day = newDate.getDate().toString().length === 1 ? `0${newDate.getDate()}` : newDate.getDate()
      const month = newDate.getMonth().toString().length === 1 ? `0${newDate.getMonth()+1}` : newDate.getMonth()+1
      const year = newDate.getFullYear()
      const hours = newDate.getHours()
      const minutes = newDate.getMinutes()
      return `${day}/${month}/${year} ${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)} น.`
    }
  },
  props: {
    data: Object as PropType<GappApi.OrderLink>,
  },
});
