import {
  StatusOrder,
  StatusOrderCustomer,
  StatusOrderSeller
} from "@geeesy/type-interfaces/lib/type-order";

export default class FuncService {
  //SECTION: validate status pickup
  validatePickup(shipping, data) {
    if (shipping) {
      return !data?.hasNoShipment && shipping.shippingMethodType !== "pickup";
    } else {
      return !data?.hasNoShipment;
    }
  }
  //SECTION: convert currency
  currency(e: string) {
    return parseFloat(e)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
  //SECTION: shipping
  setData(data) {
    if (!data) return;

    // console.log('data==>', data)
    if (data.shippingMethodType === "free") {
      return "ฟรีไม่เก็บค่าจัดส่ง";
    } else if (data.shippingMethodType === "fixedRate") {
      return (
        "ค่าจัดส่งคงที่ " +
        (
          Math.round(data.shippingMethodAttribute.shippingCost * 100) / 100
        ).toLocaleString() +
        " บาท"
      );
    } else if (data.shippingMethodType === "basedByPcs") {
      return (
        "ชิ้นแรก " +
        (
          Math.round(data.shippingMethodAttribute.shippingCostFirstPcs * 100) /
          100
        ).toLocaleString() +
        " บาท" +
        " ถัดไปชิ้นละ " +
        (
          Math.round(data.shippingMethodAttribute.shippingCostPerPcs * 100) /
          100
        ).toLocaleString() +
        " บาท"
      );
    } else if (data.shippingMethodType === "basedByStepPcs") {
      const indexLastest = data.shippingMethodAttribute.shippingCost.length - 1;
      const shippingCostArr: any = [];

      if (data.shippingMethodAttribute.shippingCost.length) {
        data.shippingMethodAttribute.shippingCost.forEach((el, index) => {
          shippingCostArr.push(Math.round(el.shippingCost * 100) / 100);
        });
      }

      const minShipcost: number = shippingCostArr.reduce((a, b) =>
        Math.min(a, b)
      );
      const maxShipcost: number = shippingCostArr.reduce((a, b) =>
        Math.max(a, b)
      );

      return (
        "คิดตามจำนวนสินค้า " +
        `${
          data.shippingMethodAttribute.minOrderTotalAmount > 0
            ? "0"
            : minShipcost.toLocaleString()
        } - ` +
        maxShipcost.toLocaleString() +
        " บาท"
      );
    } else if (data.shippingMethodType === "basedByStepWeight") {
      const indexLastest = data.shippingMethodAttribute.shippingCost.length - 1;
      const shippingCostArr: any = [];

      if (data.shippingMethodAttribute.shippingCost.length) {
        data.shippingMethodAttribute.shippingCost.forEach((el, index) => {
          shippingCostArr.push(Math.round(el.shippingCost * 100) / 100);
        });
      }

      const minShipcost: number = shippingCostArr.reduce((a, b) =>
        Math.min(a, b)
      );
      const maxShipcost: number = shippingCostArr.reduce((a, b) =>
        Math.max(a, b)
      );

      return (
        "คิดตามน้ำหนักรวมสินค้า " +
        `${
          data.shippingMethodAttribute.minOrderTotalAmount > 0
            ? "0"
            : minShipcost.toLocaleString()
        } - ` +
        maxShipcost.toLocaleString() +
        " บาท"
      );
    } else if (data.shippingMethodType === "basedByOrderPrice") {
      const indexLastest = data.shippingMethodAttribute.shippingCost.length - 1;
      const shippingCostArr: any = [];

      if (data.shippingMethodAttribute.shippingCost.length) {
        data.shippingMethodAttribute.shippingCost.forEach((el, index) => {
          shippingCostArr.push(Math.round(el.shippingCost * 100) / 100);
        });
      }
      const minShipcost: number = shippingCostArr.reduce((a, b) =>
        Math.min(a, b)
      );
      const maxShipcost: number = shippingCostArr.reduce((a, b) =>
        Math.max(a, b)
      );

      return (
        "คิดตามราคาสินค้าหักส่วนลด " +
        minShipcost.toLocaleString() +
        " - " +
        maxShipcost.toLocaleString() +
        " บาท"
      );
    }
  }

  //SECTION: order status
  //ANCHOR: method steps component of desktop
  // แจ้งชำระเงิน
  chkWaitPayment(data) {
    const form = {
      class: "",
      classLine: "",
      image: "",
      cancel:{
        status:false,
        date:data.requestCancelAt
      }
    };
    // class = class สีของ element
    // classLine = class สีของ element line
    // image = icon ที่ใช้แสดง


    const attrStatus = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnSellerWhenCanceled' : 'statusOrderOnSeller'

    const statusActive: boolean =
      data[attrStatus] == StatusOrderSeller.S3_ToConfirm ||
      data[attrStatus] == StatusOrderSeller.S4_AwaitingFulfillment ||
      data[attrStatus] == StatusOrderSeller.S5_AwaitingShipment ||
      data[attrStatus] == StatusOrderSeller.S5_AwaitingPickup ||
      data[attrStatus] == StatusOrderSeller.S6_Shipped ||
      data[attrStatus] == StatusOrderSeller.S7_AwaitingDeposit ||
      data[attrStatus] == StatusOrderSeller.S8_Completed;

    const statusNotActive: boolean =
      data[attrStatus] == StatusOrderSeller.S0_None ||
      data[attrStatus] == StatusOrderSeller.S1_AwaitingConfirm ||
      data[attrStatus] == StatusOrderSeller.S2_AwaitingPayment;

    form.class =
      data[attrStatus] == ""
        ? "bg-white"
        : null || statusNotActive
        ? "border-2 border-blue-ocean-600"
        : null || statusActive
        ? "bg-blue-ocean-600"
        : "";

    form.classLine = statusActive ? "border-blue-ocean-600" : "";
    form.cancel.status = form.class === 'border-2 border-blue-ocean-600' && data.statusOrder === StatusOrder.Canceled
    const chkActive = form.class === "bg-blue-ocean-600" ? "active" : "default";

    form.image = `assets/images/steps/${chkActive}/wait_payment.svg`;

    return form;
  }
  //ANCHOR: method steps component of desktop
  // ตรวจสอบการชำระเงิน
  chkPayment(data) {
    const form = {
      class: "",
      classLine: "",
      image: "",
      cancel:{
        status:false,
        date:data.requestCancelAt
      }
    };
    // class = class สีของ element
    // classLine = class สีของ element line
    // image = icon ที่ใช้แสดง

    const attrStatus = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnSellerWhenCanceled' : 'statusOrderOnSeller'

    const statusActive: boolean =
      data[attrStatus] == StatusOrderSeller.S4_AwaitingFulfillment ||
      data[attrStatus] == StatusOrderSeller.S5_AwaitingPickup ||
      data[attrStatus] == StatusOrderSeller.S5_AwaitingShipment ||
      data[attrStatus] == StatusOrderSeller.S6_Shipped ||
      data[attrStatus] == StatusOrderSeller.S7_AwaitingDeposit ||
      data[attrStatus] == StatusOrderSeller.S8_Completed;

    const statusNotActive: boolean =
      data[attrStatus] == StatusOrderSeller.S3_ToConfirm;

    form.class =
      data[attrStatus] == StatusOrderSeller.S2_AwaitingPayment
        ? "bg-white"
        : null || statusNotActive
        ? "border-2 border-blue-ocean-600"
        : null || statusActive
        ? "bg-blue-ocean-600"
        : "";

    form.classLine = statusActive ? "border-blue-ocean-600" : "";
    form.cancel.status = form.class === 'border-2 border-blue-ocean-600' && data.statusOrder === StatusOrder.Canceled
    const chkActive = form.class === "bg-blue-ocean-600" ? "active" : "default";

    form.image = `assets/images/steps/${chkActive}/chk_payment.svg`;

    return form;
  }
  //ANCHOR: method steps component of desktop
  // เตรียมการจัดส่ง
  chkWaitShipping(data) {
    const form = {
      class: "",
      classLine: "",
      image: "",
      cancel:{
        status:false,
        date:data.requestCancelAt
      }
    };
    // class = class สีของ element
    // classLine = class สีของ element line
    // image = icon ที่ใช้แสดง

    const attrStatus = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnSellerWhenCanceled' : 'statusOrderOnSeller'

    const statusActive: boolean =
      data[attrStatus] == StatusOrderSeller.S5_AwaitingPickup ||
      data[attrStatus] == StatusOrderSeller.S6_Shipped ||
      data[attrStatus] == StatusOrderSeller.S7_AwaitingDeposit ||
      data[attrStatus] == StatusOrderSeller.S8_Completed;

    const statusNotActive: boolean =
      data[attrStatus] == StatusOrderSeller.S4_AwaitingFulfillment ||
      data[attrStatus] == StatusOrderSeller.S5_AwaitingShipment;

    form.class =
      data[attrStatus] == StatusOrderSeller.S2_AwaitingPayment ||
      data[attrStatus] == StatusOrderSeller.S3_ToConfirm
        ? "bg-white"
        : null || statusNotActive
        ? "border-2 border-blue-ocean-600"
        : null || statusActive
        ? "bg-blue-ocean-600"
        : "";

    form.classLine = statusActive ? "border-blue-ocean-600" : "";
    form.cancel.status = form.class === 'border-2 border-blue-ocean-600' && data.statusOrder === StatusOrder.Canceled
    const chkActive = form.class === "bg-blue-ocean-600" ? "active" : "default";

    form.image = `assets/images/steps/${chkActive}/wait_shipping.svg`;

    return form;
  }
  //ANCHOR: method steps component of desktop
  // อยู่ระหว่างการจัดส่ง
  chkShipping(data) {
    const form = {
      class: "",
      classLine: "",
      image: "",
      cancel:{
        status:false,
        date:data.requestCancelAt
      }
    };
    // class = class สีของ element
    // classLine = class สีของ element line
    // image = icon ที่ใช้แสดง

    const attrStatusSeller = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnSellerWhenCanceled' : 'statusOrderOnSeller'
    const attrStatusBuyer = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnBuyerWhenCanceled' : 'statusOrderOnBuyer'


    const statusActive: boolean =
      data[attrStatusSeller] == StatusOrderSeller.S8_Completed ||
      data[attrStatusBuyer] == StatusOrderCustomer.B8_Completed;

    const statusNotActive: boolean =
      data[attrStatusSeller] == StatusOrderSeller.S5_AwaitingPickup ||
      data[attrStatusSeller] == StatusOrderSeller.S6_Shipped ||
      (data[attrStatusSeller] == StatusOrderSeller.S7_AwaitingDeposit &&
        data[attrStatusBuyer] != StatusOrderCustomer.B8_Completed);

    form.class =
      data[attrStatusSeller] == StatusOrderSeller.S2_AwaitingPayment ||
      data[attrStatusSeller] == StatusOrderSeller.S3_ToConfirm ||
      data[attrStatusSeller] == StatusOrderSeller.S4_AwaitingFulfillment ||
      data[attrStatusSeller] == StatusOrderSeller.S5_AwaitingShipment
        ? "bg-white"
        : null || statusNotActive
        ? "border-2 border-blue-ocean-600"
        : null || statusActive
        ? "bg-blue-ocean-600"
        : "";

    form.classLine = statusActive ? "border-blue-ocean-600" : "";
    form.cancel.status = form.class === 'border-2 border-blue-ocean-600' && data.statusOrder === StatusOrder.Canceled
    const chkActive = form.class === "bg-blue-ocean-600" ? "active" : "default";

    form.image = `assets/images/steps/${chkActive}/shipping.svg`;

    return form;
  }
  //ANCHOR: method steps component of desktop
  // จัดส่งเรียบร้อย
  chkSuccess(data) {
    const form = {
      class: "",
      image: "",
      cancel:{
        status:false,
        date:data.requestCancelAt
      }
    };
    // class = class สีของ element
    // image = icon ที่ใช้แสดง

    const attrStatusSeller = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnSellerWhenCanceled' : 'statusOrderOnSeller'
    const attrStatusBuyer = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnBuyerWhenCanceled' : 'statusOrderOnBuyer'

    const statusActive: boolean =
      data[attrStatusSeller] == StatusOrderSeller.S8_Completed ||
      data[attrStatusBuyer] == StatusOrderCustomer.B8_Completed;

    form.class =
      data[attrStatusSeller] == StatusOrderSeller.S2_AwaitingPayment ||
      data[attrStatusSeller] == StatusOrderSeller.S3_ToConfirm ||
      data[attrStatusSeller] == StatusOrderSeller.S4_AwaitingFulfillment ||
      data[attrStatusSeller] == StatusOrderSeller.S5_AwaitingShipment ||
      data[attrStatusSeller] == StatusOrderSeller.S6_Shipped ||
      (data[attrStatusSeller] == StatusOrderSeller.S7_AwaitingDeposit &&
        data[attrStatusBuyer] != StatusOrderCustomer.B8_Completed)
        ? "bg-white"
        : null || statusActive
        ? "bg-blue-ocean-600"
        : "";

    const chkActive = form.class === "bg-blue-ocean-600" ? "active" : "default";
    form.cancel.status = form.class === "bg-blue-ocean-600" && data.statusOrder === StatusOrder.Canceled
    form.image = `assets/images/steps/${chkActive}/success.svg`;

    return form;
  }

  // ------------------------------------
  // mobile
  // ------------------------------------
  //ANCHOR: step1 component of mobile
  chkStep1(data) {
    const form = {
      class: "",
      status: "",
      image: "",
      cancel:{
        status:false,
        date:data.requestCancelAt
      }
    };
    // class = class สีของ element
    // status = คำที่ใช้แสดง
    // image = icon ที่ใช้แสดง

    const attrStatusSeller = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnSellerWhenCanceled' : 'statusOrderOnSeller'
    const attrStatusBuyer = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnBuyerWhenCanceled' : 'statusOrderOnBuyer'


    form.class =
      data[attrStatusBuyer] == StatusOrderCustomer.B1_AwaitingConfirm ||
      (data[attrStatusBuyer] == StatusOrderCustomer.B2_AwaitingPayment &&
        data[attrStatusSeller] !== StatusOrderSeller.S3_ToConfirm)
        ? "border-2 border-blue-ocean-600 w-8 h-8"
        : "bg-blue-ocean-600";

    form.status =
      data[attrStatusBuyer] == StatusOrderCustomer.B5_AwaitingShipment ||
      data[attrStatusSeller] == StatusOrderSeller.S4_AwaitingFulfillment
        ? "ตรวจสอบการชำระเงิน"
        : data[attrStatusSeller] == StatusOrderSeller.S8_Completed ||
          data[attrStatusSeller] == StatusOrderSeller.S5_AwaitingShipment ||
          data[attrStatusBuyer] == StatusOrderCustomer.B6_Shipped
        ? "เตรียมการจัดส่ง"
        : "แจ้งชำระเงิน";

    const nameImage =
      form.status == "ตรวจสอบการชำระเงิน"
        ? "chk_payment"
        : form.status == "เตรียมการจัดส่ง"
        ? "wait_shipping"
        : "wait_payment";

    const chkActive = form.class === "bg-blue-ocean-600" ? "active" : "default";
    form.cancel.status = form.class === "w-8 h-8 border-2 border-blue-ocean-600" && data.statusOrder === StatusOrder.Canceled
    form.image = `assets/images/steps/${chkActive}/${nameImage}.svg`;

    return form;
  }

  //ANCHOR: step2 component of mobile
  chkStep2(data) {
    const form = {
      class: "",
      status: "",
      image: "",
      cancel:{
        status:false,
        date:data.requestCancelAt
      }
    };
    // class = class สีของ element
    // status = คำที่ใช้แสดง
    // image = icon ที่ใช้แสดง

    const attrStatusSeller = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnSellerWhenCanceled' : 'statusOrderOnSeller'
    const attrStatusBuyer = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnBuyerWhenCanceled' : 'statusOrderOnBuyer'

    form.class =
      data[attrStatusSeller] == StatusOrderSeller.S4_AwaitingFulfillment ||
      data[attrStatusSeller] == StatusOrderSeller.S5_AwaitingShipment ||
      data[attrStatusSeller] == StatusOrderSeller.S6_AwaitingAccepted ||
      data[attrStatusSeller] == StatusOrderSeller.S3_ToConfirm ||
      data[attrStatusSeller] == StatusOrderSeller.S6_Shipped
        ? "w-8 h-8 border-2 border-blue-ocean-600"
        : data[attrStatusSeller] == StatusOrderSeller.S8_Completed
        ? "bg-blue-ocean-600"
        : "";

    form.status =
      data[attrStatusBuyer] == StatusOrderCustomer.B0_None ||
      data[attrStatusBuyer] == StatusOrderCustomer.B2_AwaitingPayment ||
      data[attrStatusBuyer] == StatusOrderCustomer.B1_AwaitingConfirm ||
      data[attrStatusBuyer] == StatusOrderCustomer.B3_Paid
        ? "ตรวจสอบการชำระเงิน"
        : data[attrStatusSeller] == StatusOrderSeller.S6_Shipped ||
          data[attrStatusSeller] == StatusOrderSeller.S8_Completed
        ? "อยู่ระหว่างการจัดส่ง"
        : "เตรียมการจัดส่ง";

    const nameImage =
      form.status == "ตรวจสอบการชำระเงิน"
        ? "chk_payment"
        : form.status == "อยู่ระหว่างการจัดส่ง"
        ? "shipping"
        : "wait_shipping";

    const chkActive = form.class === "bg-blue-ocean-600" ? "active" : "default";
    form.cancel.status = form.class === "w-8 h-8 border-2 border-blue-ocean-600" && data.statusOrder === StatusOrder.Canceled
    form.image = `assets/images/steps/${chkActive}/${nameImage}.svg`;

    return form;
  }

  //ANCHOR: step3 component of mobile
  chkStep3(data) {
    const form = {
      class: "",
      status: "",
      image: "",
      cancel:{
        status:false,
        date:data.requestCancelAt
      }
    };
    // class = class สีของ element
    // status = คำที่ใช้แสดง
    // image = icon ที่ใช้แสดง

    const attrStatusSeller = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnSellerWhenCanceled' : 'statusOrderOnSeller'
    const attrStatusBuyer = data.statusOrder === StatusOrder.Canceled ? 'statusOrderOnBuyerWhenCanceled' : 'statusOrderOnBuyer'


    form.class =
      data[attrStatusSeller] == StatusOrderSeller.S8_Completed
        ? "bg-blue-ocean-600"
        : "";

    form.status =
      data[attrStatusBuyer] == StatusOrderCustomer.B5_AwaitingShipment ||
      data[attrStatusSeller] == StatusOrderSeller.S4_AwaitingFulfillment
        ? "อยู่ระหว่างการจัดส่ง"
        : data[attrStatusBuyer] ==
            StatusOrderCustomer.B4_AwaitingFulfillment ||
          data[attrStatusBuyer] == StatusOrderCustomer.B6_Shipped ||
          data[attrStatusBuyer] == StatusOrderCustomer.B8_Completed
        ? "จัดส่งเรียบร้อย"
        : "เตรียมการจัดส่ง";

    const nameImage =
      form.status == "อยู่ระหว่างการจัดส่ง"
        ? "shipping"
        : form.status == "จัดส่งเรียบร้อย"
        ? "success"
        : "wait_shipping";

    const chkActive = form.class === "bg-blue-ocean-600" ? "active" : "default";
    form.cancel.status = form.class === "bg-blue-ocean-600" && data.statusOrder === StatusOrder.Canceled
    form.image = `assets/images/steps/${chkActive}/${nameImage}.svg`;

    return form;
  }
}
