import { createRouter, createWebHistory } from "vue-router";

import pageDetail from "../views/detail.vue";

const routes = [
  {
    path: "/:type/:shortOrderId",
    component: pageDetail,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
